<template>
  <div class="summary-page">
    <en-table-layout ref="tableLayout" :tableData="tableData.data">
      <template slot="header">
        <el-tabs v-model="params.type" type="card" @tab-click="tabClick">
          <el-tab-pane :key="item.name" v-for="item in editableTabs" :label="item.title" :name="item.name">
            {{ item.content }}
          </el-tab-pane>
        </el-tabs>
      </template>

      <template slot="toolbar">
        <el-form-item>
          <el-input style="width:220px" clearable v-model.trim="params.label_name" placeholder="请输入标签名称进行搜索"></el-input>
        </el-form-item>
        <el-form-item class="col-auto">
          <el-button @click="searchEvent" type="primary" size="small">搜索</el-button>
          <el-button @click="addLable" type="primary" size="small">添加标签</el-button>
        </el-form-item>
      </template>

      <template slot="table-columns">
        <el-table-column label="标签名称" prop="label_name" />
        <el-table-column label="更新时间" min-width="200">
          <template slot-scope="{row}">{{ row.update_time | unixToDate('yyyy-MM-dd hh:mm') }}</template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="editLable(scope.row)">
              编辑
            </el-button>
            <el-button size="mini" type="danger" @click="deleteLable(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :page-size="tableData.page_size" :total="tableData.data_total"></el-pagination>
    </en-table-layout>
    <!-- 备注 -->
    <el-dialog width="500px" :visible.sync="dialogCatVisible" :close-on-click-modal="false"
      :close-on-press-escape="false">
      <div slot="title" class="goods-picker-title">
        <span></span>
        {{ remarkForm.label_id ? '编辑' : '添加' }}
      </div>
      <el-form :model="remarkForm" :rules="labelRules" ref="remarkForm" label-width="80px">
        <br />
        <el-form-item label="标签名称" prop="label_name">
          <el-input v-model.trim="remarkForm.label_name" placeholder="请输入10字以内的标签名称" :maxlength="10" clearable></el-input>
        </el-form-item>
        <br />
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogCatVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submitForm()" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getGiftLabelList, addGiftLable, editGiftLable, deleteGiftLable, getgiftLabelUsable } from '@/api/account';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';
export default {

  components: { EnTableLayout },
  data () {
    return {
      dialogCatVisible: false,
      remarkForm: {
        label_name: '', // 标签名称
        type: 1 // 1 礼包标签 2 区域偏好标签
      },
      // 添加、编辑分类 表单规则
      labelRules: {
        label_name: [
          { required: true, message: '请填写标签名称', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 10 个字符', trigger: 'blur' },
          { validator: this.validateName, trigger: "blur" }
        ]
      },
      editableTabs: [
        {
          title: '礼包标签',
          name: '1'
        },
        {
          title: '区域偏好标签',
          name: '2'
        }
      ],
      /** 列表loading状态 */
      loading: false,
      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        type: '1',
      },
      /** 列表数据 */
      tableData: {},
    };
  },
  mounted () {
    this.getDataList();
  },
  methods: {
    validateName (rule, value, callback) {//分类名称重复效验
      const { label_name, type } = this.remarkForm
      let datas = {
        label_name,
        type
      }
      if (this.remarkForm.label_id) datas.label_id = this.remarkForm.label_id
      getgiftLabelUsable(datas).then(res => {
        if (res.data == false) {
          callback(new Error("当前名称已存在"))
        } else {
          callback()
        }
      })
    },
    //添加标签
    addLable () {
      this.remarkForm = {
        label_name: "",
        type: this.params.type
      };
      this.dialogCatVisible = true
    },
    //编辑标签
    editLable (row) {
      this.remarkForm = {
        label_id: row.label_id,
        label_name: row.label_name,
        type: row.type
      };
      this.dialogCatVisible = true
    },
    /** 删除标签 */
    deleteLable (row) {
      this.$confirm("确定要删除该标签吗？", "提示", { type: "warning" })
        .then(() => {
          deleteGiftLable(row.label_id).then(() => {
            this.$message.success("删除成功！");
            this.getDataList();
          });
        })
        .catch(() => { });
    },
    /** 添加、编辑分类 表单提交 */
    submitForm () {
      this.$refs.remarkForm.validate((valid) => {
        if (valid) {
          const { label_id } = this.remarkForm
          if (!label_id) {
            addGiftLable(this.remarkForm).then(() => {
              this.dialogCatVisible = false
              this.$message.success('保存成功！')
              this.getDataList();
            })
          } else {
            editGiftLable(this.remarkForm).then(response => {
              this.$message.success('保存成功！')
              this.dialogCatVisible = false
              this.getDataList();
            })
          }
        } else {
          // this.$message.error('表单填写有误，请检查！')
          return false
        }
      })
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.getDataList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.getDataList();
    },

    /** 搜索事件触发 */
    searchEvent (data) {
      this.params.page_no = 1;
      this.getDataList()
    },
    tabClick () {
      this.params.label_name = ''
      this.getDataList();
    },
    getDataList () {
      this.loading = true;
      getGiftLabelList(this.params).then((response) => {
        this.loading = false;
        this.tableData = response.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.goods-picker-title {
  border-bottom: 1px solid #ccc;
  height: 51px;
  line-height: 51px;
  text-align: left;
  background-color: rgba(224, 228, 238, 1);
  font-weight: 600;
  border-radius: 5px 5px 0 0;
}

.goods-picker-title span {
  background-color: #1a43a9;
  width: 4px;
  height: 14px;
  display: inline-block;
  margin-right: 14px;
  margin-bottom: 3px;
}

/deep/ .el-dialog__header {
  padding: 0;
}
</style>
